
.blur-effect {
    filter: blur(-5px); /* Adjust the blur intensity as needed */
  }
  
  .mist-effect::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
    z-index: 1;
    opacity: 0; /* Initially hidden */
    animation: mistFade 10s infinite alternate; /* Adjust animation duration as needed */
  }
  
  @keyframes mistFade {
    0% {
      opacity: 0; /* Fully transparent */
    }
    100% {
      opacity: 1; /* Semi-transparent */
    }
  }
  
  @keyframes float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
  }
  
  .particle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5); /* Adjust particle color and opacity */
    animation-name: float;
    animation-duration: 3s; /* Adjust animation duration as needed */
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  .pollen {
    animation-delay: 1s; /* Adjust delay for staggered animation */
  }
  
  .firefly {
    animation-delay: 2s; /* Adjust delay for staggered animation */
  }
  